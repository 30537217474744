import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';

import css from './ListingPage.module.css';
import { formatMoney } from '../../util/currency';
import config from '../../config';
import { IoPeopleCircle, IoMdText } from 'react-icons/io';
import { BsPeopleFill } from 'react-icons/bs';
import { types as sdkTypes } from '../../util/sdkLoader';

const { LatLng, Money } = sdkTypes;

import { RiChatFollowUpFill } from 'react-icons/ri';
const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const formatPrice = (price, intl) => {
  return formatMoney(intl, new Money(price, config.currency));
};

const card = (price, intl, setSelectedPlan, index, isSelected, onBookingSubmit) => {
  const onClick = () => {
    // setSelectedPlan(index);
    onBookingSubmit({ selectedPlan: index });
  };

  return (
    <div
      key={price.price}
      className={css.eachPriceCard}
      style={{
        border: `2px solid ${isSelected ? 'var(--marketplaceColor)' : '#e6e6e6'}`,
        cursor: 'pointer',
      }}
      // onClick={() => setSelectedPlan(index)}
    >
      <h3> {price.title} </h3>
      <p>{price.description || ''}</p>
      <hr></hr>
      {/* 
      {price.type ? (
        <div className={css.cardType}>
          <BsPeopleFill className={css.priceIcon} />
          {price.type ? config.custom?.tierTypeOptions.find(e => e.key == price.type)?.label : ''}
        </div>
      ) : null}
      {price.subType ? (
        <div className={css.cardType}>
          <IoMdText className={css.priceIcon} />
          {price.subType
            ? config.custom?.tierSubTypeOptions.find(e => e.key == price.subType)?.label
            : ''}
        </div>
      ) : null} */}
      {price.method ? (
        <div className={css.cardType}>
          <RiChatFollowUpFill className={css.priceIcon} />
          {price.method
            ? config.custom?.tierMethodOptions.find(e => e.key == price.method)?.label
            : ''}
        </div>
      ) : null}

      <div className={css.priceCardPrice}>{formatPrice(price?.price, intl)}</div>
      <button type="button" className={css.selectButton} onClick={onClick}>
        {intl.formatMessage({ id: 'ListingPage.SectionPricingMaybe.SelectPlanButtonText' })}
      </button>
    </div>
  );
};

const SectionPricingMaybe = props => {
  const { description, publicData, intl, setSelectedPlan, selectedPlan, onBookingSubmit } = props;
  return description ? (
    <div className={css.sectionPrice}>
      <h2 className={css.descriptionTitle}>
        {intl.formatMessage({ id: 'ListingPage.SectionPricingMaybe.priceLabel' })}
      </h2>
      <div className={css.sectionPricing}>
        {publicData?.prices?.map((each, index) =>
          card(each, intl, setSelectedPlan, index, selectedPlan == index, onBookingSubmit)
        )}
      </div>
    </div>
  ) : null;
};

export default SectionPricingMaybe;
