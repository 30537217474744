import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';

import css from './ListingPage.module.css';
import { TiDocumentText } from 'react-icons/ti';
import { CgGenderFemale } from 'react-icons/cg';
import { MdWork } from 'react-icons/md';
import { FaUniversity } from 'react-icons/fa';
import { IoMdPerson } from 'react-icons/io';
import { AiTwotoneMessage } from 'react-icons/ai';
import config from '../../config';
import { universities } from '../../universities';
import { professions } from '../../professions';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionDescriptionMaybe = props => {
  const { description, publicData } = props;
  return description ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>About me</h2>
      <div className={css.descriptionSection}>
        <div className={css.descriptionSecDesc}>
          <TiDocumentText className={css.descIcon} />

          {description ? (
            <div className={css.descText}>
              <span>
                {' '}
                <FormattedMessage id="ListingPage.SectionDescriptionMaybe.biographyLabel" />
              </span>
              <p> {description} </p>
            </div>
          ) : null}
        </div>
        <div className={css.descriptionSecDesc}>
          <CgGenderFemale className={css.descIcon} />

          {publicData.gender ? (
            <div className={css.descText}>
              <span>
                {' '}
                <FormattedMessage id="ListingPage.SectionDescriptionMaybe.genderLabel" />
              </span>
              <p style={{ textTransform: 'capitalize' }}> {publicData.gender} </p>
            </div>
          ) : null}
        </div>
        {publicData.profession ? (
          <div className={css.descriptionSecDesc}>
            <MdWork className={css.descIcon} />

            <div className={css.descText}>
              <span>
                {' '}
                <FormattedMessage id="ListingPage.SectionDescriptionMaybe.professionLabel" />
              </span>
              <p style={{ textTransform: 'capitalize' }}>
                {' '}
                {professions.find(each => each.key == publicData.profession)?.label ||
                  publicData.profession}{' '}
              </p>
            </div>
          </div>
        ) : null}

        {publicData.university ? (
          <div className={css.descriptionSecDesc}>
            <FaUniversity className={css.descIcon} />

            <div className={css.descText}>
              <span>
                {' '}
                <FormattedMessage id="ListingPage.SectionDescriptionMaybe.universityLabel" />
              </span>
              <p style={{ textTransform: 'capitalize' }}>
                {' '}
                {universities.find(each => each.key == publicData.university)?.label ||
                  publicData.university}{' '}
              </p>
            </div>
          </div>
        ) : null}
        {publicData.race ? (
          <div className={css.descriptionSecDesc}>
            <IoMdPerson className={css.descIcon} />

            <div className={css.descText}>
              <span>
                {' '}
                <FormattedMessage id="ListingPage.SectionDescriptionMaybe.raceLabel" />
              </span>
              <p style={{ textTransform: 'capitalize' }}>
                {' '}
                {config.custom?.raceOptions.find(each => each.key == publicData.race)?.label ||
                  publicData.race}{' '}
              </p>
            </div>
          </div>
        ) : null}

        <div className={css.descriptionSecDesc}>
          <AiTwotoneMessage className={css.descIcon} />

          {description ? (
            <div className={css.descText}>
              <span>
                {' '}
                <FormattedMessage id="ListingPage.SectionDescriptionMaybe.responseTimeLabel" />
              </span>
              <p> 2 hours </p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  ) : null;
};

export default SectionDescriptionMaybe;
