import React, { useState } from 'react';

import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import NoIGLinkPopup from '../NoIGLinkPopup/NoIGLinkPopup';

import css from './ListingPage.module.css';
import { BsInstagram } from 'react-icons/bs';
import config from '../../config';
const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionSocialsMaybe = props => {
  const { description, currentUser, publicData, instagram } = props;
  const instaID = String(instagram);
  const instaFollowers = publicData?.instaFollowers;
  const instaFollowersLabel = config.custom?.instaFollowersOptions?.find(
    e => e.key == String(instaFollowers)
  )?.label;

  // State to manage popup visibility
  const [showPopup, setShowPopup] = useState(false);

  // Function to toggle popup
  const togglePopup = () => setShowPopup(!showPopup);

  // Check if instaID is one of the specified IDs
  const allowedIDs = ['j_murda732', 'yung2mon3y', 'brandanbala'];
  const isAllowedID = allowedIDs.includes(instaID);

  return instaID? (
    <div className={css.sectionSocials}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.SectionSocialsMaybe.socialMediaLabel" />
      </h2>
      <div className={css.socials}>
        <div className={css.social}>
          {/* Show link or popup trigger based on instaID */}
          {isAllowedID ? (
            <button type="button" className={css.socailButton}>
              <a target="_" href={'https://instagram.com/' + instaID}>
                <BsInstagram className={css.socialicon} />@{instaID}
                {instaFollowersLabel ? ' (' + instaFollowersLabel + ') ' : ''}
              </a>
            </button>
          ) : (
            <button type="button" className={css.socailButton} onClick={togglePopup}>
                <BsInstagram className={css.socialicon} />@{'********'}
                {instaFollowersLabel ? ' (' + instaFollowersLabel + ') ' : ''}
            </button>
          )}
        </div>
      </div>

      {/* Show NoIGLinkPopup if showPopup is true and instaID is not allowed */}
      {!isAllowedID && showPopup && <NoIGLinkPopup closePopup={togglePopup} />}
    </div>
  ) : null;
};

export default SectionSocialsMaybe;