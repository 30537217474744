import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton, PrimaryButton, SecondaryButton } from '../../components';
import { AiFillStar } from 'react-icons/ai';

import css from './ListingPage.module.css';
import classNames from 'classnames';
import Decimal from 'decimal.js';

const getCertificateInfo = (certificateOptions, key) => {
  return certificateOptions.find(c => c.key === key);
};

const SectionHeading = props => {
  const {
    richTitle,
    listingCertificate,
    certificateOptions,
    showContactUser,
    onContactUser,
    publicData,
    onBookingSubmit,
    selectedPlan,
    metadata,
  } = props;

  const stars = metadata?.avgRating;
  const avgRating = stars ? new Decimal(stars).div(100).toNumber() : null;
  const { gender } = publicData;
  const certificate = getCertificateInfo(certificateOptions, listingCertificate);
  const showCertificate = certificate && !certificate.hideFromListingInfo;
  return (
    <div className={css.sectionHeading}>
      <div className={css.heading}>
        <h1 className={css.title}>{richTitle}</h1>
        <div className={css.author}>
          {gender ? <span style={{ textTransform: 'capitalize' }}>{gender}</span> : null}

          {avgRating && (
            <>
              <span className={css.age}> {avgRating}</span>
              <span>
                <AiFillStar className={css.star} />
              </span>
            </>
          )}
        </div>
      </div>


    </div>
  );
};

export default SectionHeading;
