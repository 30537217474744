import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ResponsiveImage, Modal, ImageCarousel } from '../../components';

import css from './ListingPage.module.css';

const SectionPhotosMaybe = props => {
  const {
    title,
    listing,
    isOwnListing,
    editParams,
    handleViewPhotosClick,
    imageCarouselOpen,
    onImageCarouselClose,
    onManageDisableScrolling,
  } = props;

  const hasImages = listing.images && listing.images.length > 0;

  const firstImage = hasImages ? listing.images[0] : null;

  if (!hasImages) return null;
  // Action bar is wrapped with a div that prevents the click events
  // to the parent that would otherwise open the image carousel

  const viewPhotosButton = hasImages ? (
    <button className={css.viewPhotos} onClick={handleViewPhotosClick}>
      <FormattedMessage
        id="ListingPage.viewImagesButton"
        values={{ count: listing.images.length }}
      />
    </button>
  ) : null;
  // console.log({ aa: listing.images });
  return (
    <div className={css.SectionPhotosMaybe}>
      <h2 className={css.descriptionTitle}>Photos</h2>
      <div className={css.SectionPhotosImages}>
        {listing.images
          ? listing.images.map(each => (
              <ResponsiveImage
                key={each.id.uuid}
                rootClassName={css.eachImagesImage}
                alt={title}
                image={each}
                variants={[
                  'portrait-crop',
                  // 'w:250;h:250;fit:scale',
                  'scaled-large',
                  // 'default',
                  // 'landscape-crop2x',
                  // 'landscape-crop4x',
                  // 'landscape-crop6x',
                ]}
              />
            ))
          : null}
      </div>

      {/* {viewPhotosButton} */}

      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={imageCarouselOpen}
        onClose={onImageCarouselClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ImageCarousel
          images={listing.images}
          imageVariants={['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge']}
        />
      </Modal>
    </div>
  );
};

export default SectionPhotosMaybe;
